// SAP Fiori
import '@ui5/webcomponents/dist/Popover.js';
import '@ui5/webcomponents/dist/Button.js';
import '@ui5/webcomponents/dist/Label.js';
import '@ui5/webcomponents/dist/Input.js';
import '@ui5/webcomponents/dist/TabContainer.js';
import '@ui5/webcomponents/dist/Tab.js';

// Fiori Icons
import '@ui5/webcomponents-icons/dist/grid.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@ui5/webcomponents-icons/dist/menu2.js';
import '@ui5/webcomponents-icons/dist/user-settings.js';

import '../styles/styles.scss';
import '../styles/fiori.css';
import '../../node_modules/select2/dist/css/select2.min.css';
import '../../node_modules/select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.rtl.min.css';

import '../styles/pricelogs.css';
import '../styles/tabs.css';
import '../styles/animations.css';
import '../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import '../../node_modules/tabulator-tables/dist/css/tabulator_bootstrap5.min.css';
import '../../node_modules/daterangepicker/daterangepicker.css';
import '../../node_modules/bootstrap-fileinput/css/fileinput.min.css';
import '../../node_modules/bootstrap-fileinput/themes/explorer/theme.min.css';
import '../styles/main.css';

/* global Stimulus */
import '@hotwired/turbo';
import 'bootstrap';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
